<template>
    <div id="app">
        <div class="container">
            <img src="/banner.jpg" title="Ignite Flow Arts Gathering 2022"/>
            <article class="is-size-5 intro" >
                <h1 class="title is-1 mb-6 site-title">Ignite Flow Arts Gathering</h1>
                <div class="has-text-left">
                    <p>Ignite Flow Arts Gathering is a 3 day, full immersion flow arts festival where you camp, train, eat, flow and dance with fellow enthusiasts.</p>
                    <br/>
                    <p>Ignite will return on a to be determined date!</p>
                </div>
              <!-- <b-button tag="a" title="Order tickets" class="mt-6" rounded size="is-large" href="https://www.eventbrite.com/e/ignite-flow-arts-gathering-2022-tickets-288836125627" >Order tickets</b-button> -->
            </article>
            
            <h2 class="title is-4 mt-6">Aftermovie Ignite 2022</h2>
            <iframe class="aftermovie-iframe mt-3" src="https://www.youtube.com/embed/watch?v=qbh0YyLSLsA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="is-size-7 has-text-center mt-3 pb-3">Foto credits:
            <a href="https://www.capturemyspirit.com/" target="_blank" >Capture My Spirit</a>,  
            <a href="https://gc-photographie.fr" target="_blank" >Gwendoline Chopineau</a>,  
            <a href="https://www.facebook.com/fotografielexooo" target="_blank" >Fotografie Lexooo</a>
        </div>
    </div>
</template>

<script>

export default {
    name: 'App',
    components: {
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo&family=Libre+Baskerville:wght@700&display=swap');


@import "~bulma/sass/utilities/_all";
$body-background-color: rgb(22 17 34);
$title-family: 'Libre Baskerville';
$link: rgb(108 106 255);
$primary: rgb(108 106 255);
$text: hsl(241deg 69% 87%);
$title-color: white;
@import "~bulma";
@import "~buefy/src/scss/buefy";

html, body {
    font-size: 18px;
    height: 100%;
    font-family: 'Arvo', serif;
}

a:hover {
    color: white;
    text-decoration: underline;
}

.button {
    color: #f3e104;
    background-color: hsl(0deg 81% 33%);
    border: none;

    &:hover {
        color: white;
    }
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    padding-left: 3em;
    padding-right: 3em;
}

article {
    text-align: left;
    padding: 4em;
    background: rgb(29 22 46);
}

article div {
    padding-top: 1em;
}

.aftermovie-iframe {
    aspect-ratio: 16 / 9;
    width: 100%;
}

.title.is-3,
.title.site-title {
    color: rgb(0 185 125);
}

.title {
    color: rgb(255 142 108);
}

.site-title-date {
    color: white;
}

.flexbox {
    display: flex;
    flex-direction: row;
    gap: 3rem;
}

.intro {
    margin-top: -1em;
    text-align: center;

}

.location {
    flex-shrink: false;

    p {
        white-space: nowrap;
    }
}

@media (max-width: 960px) {
    .flexbox {
        flex-direction: column;
        gap: 0;
    }

    .location p {
        white-space: inherit;
    }

    .site-title {
        font-size: 2em!important;
    }

    article {
        padding-top: 2em;
        padding-left: 1em;
        padding-right: 1em;
        // padding-bottom: 2em;
    }

    .flexbox.mt-6, article.mt-6, .aftermovie-iframe.mt-6 {
        margin-top: 0!important;
    }

    .faq {
        border-right: none;
        padding-right: none;
    }

    #app {
        padding-right: 0;
        padding-left: 0;
    }

}

</style>
